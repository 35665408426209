import { Component, OnInit } from '@angular/core';
import { ShowsService } from '../../services/shows.service';

@Component({
  selector: 'app-roof',
  templateUrl: './roof.component.html',
  styleUrls: ['./roof.component.scss']
})
export class RoofComponent implements OnInit {
  donors: any;

  constructor(private service: ShowsService) { }

  ngOnInit() {
    this.service.getRoofDonors().subscribe((donors: any) => {
      this.donors = donors.body.donors;
    });
  }

}
