<span tooltip="This is a standing/dancing show. Seating will be limited to those who really need it, so please keep your chairs at home if you can."  [options]="{placement: 'top', theme: 'light', 'show-delay': 250, 'hide-delay': 250}">
  <img src="assets/images/chairIcon.svg" alt="Standing show (limited chairs)" width="40" />
</span>

<!-- <div class="modal fade show" *ngIf="modalOpen" tabindex="-1" role="dialog" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Limited Seating</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>This is a standing/dancing show. Seating will be limited to those who really need it, so please keep your chairs at home if you can.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div> -->