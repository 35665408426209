<div class="row">
    <div class="col-lg-12 col-xl-7 offset-xl-1 translucent-bg simple-page">
        <h1>Sh*tty Ticket Swap</h1>
        <div *ngIf="maintenanceMode">
            <p>We are fixing stuff and enhancing your ticket swap experience. Be back soon!!</p>
        </div>
        <div *ngIf="(maintenanceMode && bypassCode === 'shittydeveloper') || !maintenanceMode">


            <p class="lead">
                Ticket Swap is a safe place to initiate the transfer of tickets. All transfers will happen via our ticketing system Ticket Spice and their payment processor Webconnex Payments via WePay. This page helps connect buyers with sellers. Rest assured, all ticket sellers are legitimate and are verified through the Ticket Spice system.</p> 
            <p class="lead">
                Please note this is a system we created and are still working the bugs out. We do not have the ability to provide quick answers when you have a question or problem (we, unfortunately, have day jobs).
            </p>
            <h2 class="h4">Selling Process</h2>
            <p class="lead">
                To list tickets for sale, please fill out the form below. You must enter the email address that was used to buy the tickets. You will receive a “request to purchase” email when a buyer is interested in your tickets. Follow the instructions in that email to initiate the transfer. The buyer then must complete the transaction (and pay for the tickets) to complete the transfer.</p>
            <p class="lead">If you do not want to sell the tickets to the buyer, or if the buyer fails to complete the transaction in a timely manner, you can relist the tickets. You relist by clicking the link in the “request to purchase” email or re-submitting the tickets in the form below. If a buyer requests to purchase a fraction of your tickets, you may choose to sell them only the requested tickets, and relist the unsold tickets to sell to a different buyer.
            </p>
            <h2 class="h4">Buying Process</h2>
            <p class="lead">
                You can find available tickets listed below. Click on a listing, enter your email and submit. This will send an email to the seller, and it's up to them to decide to continue with the sale and transfer. Once the seller confirms the sale, you will receive an email to enter payment to complete the transaction through Ticket Spice. Do not contact the seller directly to arrange payment.</p>
            <button class="btn btn-primary" (click)="toggleTicketSubmitPanel(true)" *ngIf="!openTicketSubmit">Sell your tickets</button>
            <div class="content" *ngIf="openTicketSubmit">
                <h2>Sell your tickets</h2>
                <form [formGroup]="submitForm" class="needs-validation">
                <div class="row">
                    <div class="col-md-8">
                        <label class="form-label" for="name">Select a show:</label>
                        <select class="form-control" formControlName="formId">
                        <option *ngFor="let option of availableShows" [value]="option.id">
                            {{ option.name }}
                        </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="showErrors && submitForm.controls['formId'].invalid">
                            Field is required
                        </div>
                    </div>
                </div>

                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label"  for="name">Email:</label>
                            <input class="form-control" type="text" id="customerEmail" formControlName="customerEmail">
                            <div class="invalid-feedback" *ngIf="showErrors && submitForm.controls['customerEmail'].invalid">
                                invalid email
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label" for="name">Number Of Tickets:</label>
                            <input class="form-control" type="number" min="1" id="numberOfTickets" formControlName="numberOfTickets">
                            <div class="invalid-feedback" *ngIf="showErrors && submitForm.controls['numberOfTickets'].invalid">
                                Field is required
                            </div>
                        </div>  
                    </div>
                    <div class="row"  *ngIf="showErrors">
                        <div class="col-md-4" class="invalid-feedback">                        
                            {{submitForm.errors}}
                        </div>
                    </div>
                    <div class="row"  *ngIf="showSuccess">
                        <div class="col-md-4" class="valid-feedback">                        
                            {{showSuccess}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">     
                            <button class="btn btn-primary" type="submit" (click)="submitTicketsForSale()">Submit</button>
                            <button class="btn btn-secondary" (click)="toggleTicketSubmitPanel(false)" *ngIf="openTicketSubmit">Close</button>
                        </div>
                    </div>
                </form>

            </div>
            <div class="row mt-5 border-top">
            </div>
            <div class="content row ticketsForSale" *ngIf="!showTicketSelectForm">
                <div class="col-md-12 mt-4">
                    <h2>Tickets For Sale</h2>
                    <div *ngIf="availableTickets.length < 1">No tickets for sale at the moment. Check back later.</div>
                    <div *ngIf="availableTickets.length > 0">
                        <p class="lead">
                            Click on a show below to start the transfer process with the ticket holder.
                        </p>
                        <div class="list-group">
                            <button class="list-group-item" *ngFor="let ticket of availableTickets" (click)="selectTicket(ticket)">{{ticket.formName}} - {{ticket.firstName}} is selling {{ticket.numberOfTickets}} ticket(s)</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content row selectTickets" *ngIf="showTicketSelectForm">
                <div class="col-md-12 mt-4">
                    <h2>Submit offer to purchase tickets for:</h2>
                    <h5>{{ticketSelectForm.get('showData').value}}</h5>
                    <p class="lead">
                    After submitting this form, an email will be sent to the seller with the information from this form indicating that you want to purchase the tickets. If the seller commits to the sale, you will then receive an email from Ticket Spice with payment instructions to complete the transaction.
                    </p>
                    <form [formGroup]="ticketSelectForm" class="needs-validation">
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <label class="form-label" for="buyerFirstName">First Name:</label>
                                <input class="form-control" type="text" id="buyerFirstName" formControlName="buyerFirstName">
                                <div class="invalid-feedback" *ngIf="showErrors && ticketSelectForm.controls['buyerFirstName'].invalid">
                                    Field is required
                                    </div>
                            </div>  
                            <div class="col-md-6 mt-4">
                                <label class="form-label" for="buyerLastName">Last Name:</label>
                                <input class="form-control" type="text" id="buyerLastName" formControlName="buyerLastName">
                                <div class="invalid-feedback" *ngIf="showErrors && ticketSelectForm.controls['buyerLastName'].invalid">
                                    Field is required
                                </div>
                            </div>
                            <div class="col-md-6 mt-4">
                                <label class="form-label"  for="buyerEmail">Email:</label>
                                <input class="form-control" type="text" id="buyerEmail" formControlName="buyerEmail">
                                <div class="invalid-feedback" *ngIf="showErrors && ticketSelectForm.controls['buyerEmail'].invalid">
                                    Invalid email
                                </div>
                            </div>
                            <div class="col-md-12 mt-4">
                                <label class="form-label" for="message">Message:</label>
                                <br /><small class="text-muted">Include a message to the seller. They will decide if they want to sell you the tickets or not, so try not to sound like a scammer :)</small>

                                <textarea class="form-control" type="text" id="message" formControlName="message"></textarea>
                                <div class="invalid-feedback" *ngIf="showErrors && ticketSelectForm.controls['message'].invalid">
                                    Field is required
                                </div>
                            </div>

                            <div class="col-md-12 mt-4 form-check">
                                <input class="form-check-input" type="checkbox" id="acknowledgementOfData" formControlName="acknowledgementOfData" />
                                <label class="form-check-label" for="checkbox">I agree to send my contact info to the seller of these tickets</label>

                                <div class="invalid-feedback" *ngIf="showErrors && ticketSelectForm.controls['acknowledgementOfData'].invalid">
                                    Field is required
                                </div>
                            </div>
                        </div>
                        <div class="row"  *ngIf="showErrors">
                            <div class="col-md-4" class="invalid-feedback">                        
                                {{ticketSelectForm.errors}}
                            </div>
                        </div>
                        <div class="row"  *ngIf="showSuccess">
                            <div class="col-md-4" class="valid-feedback">                        
                                {{showSuccess}}
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col-md-4">     
                                <button class="btn btn-primary" type="submit" (click)="submitSelectedTicket()">Submit</button>
                                <button class="btn btn-secondary" (click)="backToTicketsView()">Close</button>
                            </div>
                        </div>
                        </form>


                </div>
            </div>
        </div>
    </div>

</div>