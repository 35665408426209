import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() fixed = false;
  shows$: Observable<[]>;
  randomNumber: string;
  showSpotify = false;
  constructor(
    private store$: Store<any>,
  ) { }

  ngOnInit() {
    this.shows$ = this.store$.select('featuredShows');
    const currentDate: Date = new Date();
    const cutoffDate: Date = new Date('2023-03-18T04:00:00.000Z');
    
    const isAfterCutoff: boolean = currentDate.getTime() > cutoffDate.getTime();
    
    if (isAfterCutoff) {
      this.showSpotify = true;
    }
  }
}
