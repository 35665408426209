import { IPayloadAction } from '../payloadAction';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { ShowsService } from '../../services/shows.service';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { switchMap, withLatestFrom, catchError, mergeMap } from 'rxjs/operators';
import { NoOp } from './ourTeam';
import { getOldOrNewFile } from '../../util/fileUrl';

export const FETCH_DESIGNERS = '[designers] fetch';
export const FETCH_DESIGNERS_SUCCESS = '[designers] fetch success';
export const SELECT_DESIGNER = '[designers] select';
export const FETCH_POSTERS = '[posters] fetch';
export const FETCH_POSTERS_SUCCESS = '[posters] fetch success';

export class FetchDesigners implements IPayloadAction {
  readonly type = FETCH_DESIGNERS;
}

export class FetchDesignersSuccess implements IPayloadAction {
  readonly type = FETCH_DESIGNERS_SUCCESS;
  constructor(public payload: IDesigner[]) { }
}

export class SelectDesigner implements IPayloadAction {
  readonly type = SELECT_DESIGNER;
  constructor(public payload: IDesigner) { }
}

export class FetchPosters implements IPayloadAction {
  readonly type = FETCH_POSTERS;
}

export class FetchPostersSuccess implements IPayloadAction {
  readonly type = FETCH_POSTERS_SUCCESS;
  constructor(public payload: any) { }
}

export interface IPoster {
  url?: string;
}

export interface IDesigner {
  id?: string;
  name?: string;
  url?: string;
  bio?: string;
  image?: string;
  posters?: IPoster[]
}

export interface IPoster {
  url?: string;
  sessionNumber?: string;
  date?: string;
}

export const initialDesignerState: IDesigner[] = [];

export const initialSelectedDesignerState: IDesigner = {};

export const initialPostersState: any = {};

export function designersReducer(initialDesignersState: IDesigner[], action: IPayloadAction) {
  switch (action.type) {
    case FETCH_DESIGNERS_SUCCESS:
      return action.payload;
    default: {
      return initialDesignersState;
    }
  }
}

export function selectedDesignerReducer(initialSelectedDesignersState: IDesigner, action: IPayloadAction) {
  switch (action.type) {
    case SELECT_DESIGNER:
      return action.payload;
    default: {
      return initialSelectedDesignersState;
    }
  }
}

export function postersReducer(initialPostersState: any, action: IPayloadAction) {
  switch (action.type) {
    case FETCH_POSTERS_SUCCESS:
      return action.payload;
    default: {
      return initialPostersState;
    }
  }
}

@Injectable()
export class designerEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private showsService: ShowsService,
  ) {
  }

  fetchDesigners$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_DESIGNERS),
    withLatestFrom(
      this.store$.select('designers')
    ),
    switchMap(([action, designers]: [IPayloadAction, IDesigner[]]) => {
      // return NoOp if request to fetch team is already in state.
      if (designers && designers.length > 0) {
        return of(new NoOp());
      }

      const getDesignersRequest = this.showsService.getDesigners();

      return from(getDesignersRequest).pipe(
        mergeMap((result: any) => {
          const designersFetch = result.body.map((designer) => formatDesigner(designer));
          return of(
            new FetchDesignersSuccess(designersFetch),
          );
        }),
        catchError((error) => of(new NoOp()))
      );
    }),
  ));

  fetchPosters$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_DESIGNERS),
    withLatestFrom(
      this.store$.select('posters')
    ),
    switchMap(([action, posters]: [IPayloadAction, any]) => {
      // return NoOp if request to fetch posters is already in state.
      if (posters && Object.keys(posters).length > 0) {
        return of(new NoOp());
      }

      const getPostersRequest = this.showsService.getPosters();

      return from(getPostersRequest).pipe(
        mergeMap((result: any) => {
          const designerPosters = {};
          result.body.forEach((poster) => {
            if (!designerPosters[poster.PosterDesignedBy]) {
              designerPosters[poster.PosterDesignedBy] = [];
            }
            designerPosters[poster.PosterDesignedBy].push(
              {
                name: poster.PosterDesignedBy,
                url: getOldOrNewFile(poster.PosterName, poster.PosterHash, poster.PosterOldName, 'show-images', true),
                sessionNumber: poster.SessionNumber,
                date: poster.Date
              }
            )
          });
          return of(
            new FetchPostersSuccess(designerPosters),
          );
        }),
        catchError((error) => of(new NoOp()))
      );
    }),
  ));
}

function formatDesigner(designer): IDesigner {
  return {
    id: designer.ID,
    name: designer.Name,
    bio: designer.Bio,
    url: designer.Url,
    image: getOldOrNewFile(designer.ImageName, designer.ImageHash, designer.OldImageName, 'Uploads', true)
  }
}
