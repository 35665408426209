<div class="row" *ngIf="(shows$ | async) as shows">
  <div class="offset-1 col-10">
    <div class="row">
      <div class="col-sm-12 col-md-6"><h1>Past Shows</h1></div>
      <div class="col-sm-12 col-md-6">

      <h2 class="yearLabel">Year: </h2>
      <div class="form-group nav-item yearsForm">
        <form [formGroup]="yearsForm">
        <select class="form-control" id="exampleFormControlSelect1" formControlName="year">
          <option *ngFor="let year of (years$ | async)" [ngValue]="year" >{{year}}</option>
        </select>
        </form>
      </div>
      </div>
      <div class="dotted-line"></div>
      <div [ngClass]="{'col-xs-12 col-sm-6 col-lg-4': display === 'grid', 'col-12': display ==='list'}" *ngFor="let show of shows">
        <app-show-card [show]="show" [display]="display" past="true"></app-show-card>
      </div>
    </div>
  </div>
</div>