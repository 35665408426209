<div class="row" *ngIf="(team$ | async) as team">
  <div class="offset-1 col-10">
    <h1>The team</h1>
    <h2>Current Staff</h2>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-lg-4 mb-2" *ngFor="let member of team.staff">
        <!-- <img src="{{member.image}}" alt="{{member.name}}" /> -->
        <div class="staff-image" [style.background]="'url(' + member.image + ')'"></div>
        <h3>{{ member.name }}</h3>
        <div [innerHTML]="member.bio"></div>
      </div>
    </div>
    <h2>Heroes</h2>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-lg-4 mb-4" *ngFor="let member of team.heros">
        <!-- <img src="{{member.image}}" alt="{{member.name}}" /> -->
        <div class="staff-image" [style.background]="'url(' + member.image + ')'"></div>
        <h3>{{ member.name }}</h3>
        <div [innerHTML]="member.bio"></div>
      </div>
    </div>
  </div>
</div>