<div class="row" *ngIf="(designer$ | async) as designer">
  <div class="offset-1 col-10">
    <h1>{{designer.name}}</h1>
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-6" [innerHTML]="designer.bio"></div>
      <div class="col-xs-12 col-sm-6">
        <img *ngIf="designer.image" src="{{designer.image}}" alt="{{designer.name}}" />
      </div>
    </div>
    <a *ngIf="designer.url" target="_blank" href="{{designer.url}}">Website</a>
    <div class="row">
      <div class="poster col-xs-12 col-sm-6 col-lg-4" *ngFor="let poster of designer.posters">
        <h2>Session {{poster.sessionNumber}}</h2>
        <img src="{{poster.url}}" alt="{{poster.sessionNumber}}" />
      </div>
    </div>
  </div>
</div>