import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { IDesigner, FetchPosters, FetchDesigners } from '../../state/staff/designers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-selected-designer',
  templateUrl: './selected-designer.component.html',
  styleUrls: ['./selected-designer.component.scss']
})
export class SelectedDesignerComponent implements OnInit {
  designer$: Observable<IDesigner>;
  posters$: Observable<any>;
  constructor(
    private store$: Store<any>,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.store$.dispatch(new FetchPosters());
    this.store$.dispatch(new FetchDesigners());
    this.designer$ =
      combineLatest([
        this.store$.select('designers'),
        this.store$.select('posters')
      ]).pipe(
        map(([designers, posters]: [IDesigner[], any]) => {
          const designerId = this.route.snapshot.paramMap.get('id');
          const selectedDesigner = designers.filter((designer) => designer.id === designerId);
          return {
            ...selectedDesigner[0],
            posters: selectedDesigner[0] && posters[selectedDesigner[0].name]
          }
        })
      )
  }
}
