import { Component, OnInit } from '@angular/core';
import { ShowsService } from '../../services/shows.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FetchShow, FetchShowSuccess } from '../../state/show/show';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  show: any;
  show$: Observable<any>;
  copyHeight = '350px';
  showButtonText = 'show more';
  showPics: any;
  sanitizedUrls: Map<string, SafeResourceUrl> = new Map;

  constructor(
    private service: ShowsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private store$: Store<any>,
  ) { }

  ngOnInit() {
    // clear show before loading the new
    this.store$.dispatch(new FetchShowSuccess(undefined));
    const showId = this.route.snapshot.paramMap.get('id');
    this.store$.dispatch(new FetchShow(showId));

    this.service.getShowPics(showId).subscribe(pics => {
      this.showPics = pics.body;
    });
    this.show$ = this.store$.select('currentShow').pipe(
      take(2),
    );
    window.scrollTo(0, 0);
  }

  extractOrReturnUrl(url) {
    if (!url) {
      return;
    }
    let sanitizedUrl = this.sanitizedUrls.get(url);
    if (!sanitizedUrl) {
      if (url.startsWith('http') || url.startsWith('https')) {
        sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      } else {
        const extractedUrl = url.split('src="').pop().split('"')[0];
        sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(extractedUrl);
      }
      this.sanitizedUrls.set(url, sanitizedUrl);
    }
    return sanitizedUrl;
  }

  extractYouTubeId(url) {
    if (!url) {
      return;
    }
    let sanitizedUrl = this.sanitizedUrls.get(url);
    if (!sanitizedUrl) {
      let videoId = url.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}`);
      this.sanitizedUrls.set(url, sanitizedUrl);
    }
    return sanitizedUrl;
  }

  convertLinkToIcon(link) {
    if (link.indexOf('facebook') !== -1) {
      return `<img src="assets/images/facebook.png" target="_blank" width="25" />`;
    } else if (link.indexOf('twitter') !== -1) {
      return `<img src="assets/images/twitter.png" target="_blank" width="25"  />`;
    } else if (link.indexOf('instagram') !== -1) {
      return `<img src="assets/images/instagram.png" target="_blank" width="25"  />`;
    } else {
      return `<img src="assets/images/web.png" target="_blank" width="25"  />`;
    }
  }

  getShowImageUrl(filename) {
    return `${environment.assetsHost}/assets/show-images/${filename}`;
  }

  showMore() {
    if (this.showButtonText === 'show more') {
      this.copyHeight = '100%';
      this.showButtonText = 'show less';
    } else {
      this.copyHeight = '350px';
      this.showButtonText = 'show more';
      window.scrollTo(0, 0);
    }
  }
}
