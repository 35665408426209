
<div class="row">
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-1 translucent-bg simple-page">
      <h1>Your Sh*tty’s been sheltered!</h1>
      <p>Thanks to all who donated, we couldn’t have done it without you!</p>
      <p>We’re sending a huge THANK YOU! to everyone who pitched in to help us literally put a lid on the music. Your generosity is overwhelming, and it’s good to know so many of our fans have our back when it comes to keeping this little venue going. While y’all know who you are, we just wanted to mention each and every one of you. Thanks again, and we’ll see you at our 2020 Sessions!</p>
      <img src="assets/images/roof.jpg" alt="shitty roof" class="roof-image" />

    </div>
    <div class="col-md-12 col-lg-6 col-xl-5">
      <h2>Our Donors</h2>
      <p class="text-muted">(For those of you who chose to remain anonymous, your secret is safe with us, but know you have our ever loving gratitude.)</p>
      <ul class="list-group">
        <li class="list-group-item bg-dark" *ngFor="let donor of donors">{{donor}}</li>
      </ul>
    </div>
  </div>