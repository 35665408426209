<div class="row">
    <div class="col-lg-12 col-xl-7 offset-xl-1 translucent-bg simple-page rogue">
        <!-- <div class="rogue-page-background">
        </div> -->
      <h1>What are the Rogue Sessions?</h1>
      <div class="row">
        <div class="content col-sm-12 col-md-8">

            <p>We have plenty of shows in the season that are perfect for relaxing in your chair and listening to great music. Rogue Sessions are shows curated for those who like a little more energy and a lot fewer chairs.</p>
            <p>So folks, NO chairs in the Barn at these shows. We want movement and dancing to match the energy the bands are bringing. Expect high-energy performances where the crowd moves freely and every night is unpredictable.</p> 
            <p>Are you ready to go Rogue?</p>
    
          </div>
          <div class="col-sm-8 col-md-4">
            <img src="/assets/images/rogue.png" alt="rogue session" />

          </div>

      </div>

    </div>
    <div class="col-lg-12 col-xl-3 sidebar">
        <app-sidebar></app-sidebar>
      </div>
  </div>
  
