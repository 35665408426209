import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IShowForm, ITicket, ITicketClaim, TicketsService } from 'src/app/services/tickets.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ticket-swap',
  templateUrl: './ticket-swap.component.html',
  styleUrls: ['./ticket-swap.component.scss']
})
export class TicketSwapComponent implements OnInit, OnDestroy {
  openTicketSubmit = false;
  submitForm: FormGroup;
  selectedShow;
  availableShows: IShowForm[];
  availableTickets: ITicket[] = [];//Observable<ITicket[]>;
  showErrors = false
  showSuccess = false;
  showTicketSelectForm = false;
  ticketSelectForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  bypassCode: string;
  maintenanceMode = false;

  constructor(
    private fb: FormBuilder,
    private ticketsService: TicketsService,
    private route: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.submitForm = this.fb.group({
      formId: ['', Validators.required],
      publishedPath: ['', Validators.required],
      formName: ['', Validators.required],
      customerEmail: ['', [Validators.required, Validators.email]],
      numberOfTickets: ['', Validators.required],
    });

    this.route.queryParams
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(params => {
      console.log('params: ', params['bypasscode'])
      this.bypassCode = params['bypasscode']; 
   });

    this.submitForm.get('formId').valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value)=> {
        this.selectedShow = this.availableShows.find((show) => show.id === parseInt(value));
        this.submitForm.get('formName').setValue(this.selectedShow.name);
        this.submitForm.get('publishedPath').setValue(this.selectedShow.publishedPath || 'https://shittybarnsessions.com');
      })

    this.ticketsService.getAvailableTickets()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((tickets) => {
        // this.availableTickets = tickets.body as ITicket[];
        this.availableTickets = (tickets.body as ITicket[]).filter(ticket => {
          const showDate = ticket.formName.split(' //')[0].replace(/\./g, '/');
          const showDateAsDateTime = new Date(showDate);
          showDateAsDateTime.setDate(showDateAsDateTime.getDate() + 1);
          
          // For developing locally with test data.
          // return ticket;

          const today = new Date();
          if (today <= showDateAsDateTime) {
            return ticket;
          }
        });
      });

    this.ticketSelectForm = this.fb.group({
      id: ['', Validators.required],
      showData: ['', Validators.required],
      publishedPath: [''],
      buyerEmail: ['', [Validators.required, Validators.email]],
      buyerFirstName: ['', Validators.required],
      buyerLastName: ['', Validators.required],
      numberOfTickets: ['', Validators.required],
      acknowledgementOfData: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  toggleTicketSubmitPanel(value: boolean) {
    this.openTicketSubmit = value;
    if (value && !this.availableShows) {
      this.ticketsService.getAvailableForms()
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((shows) => {
          this.availableShows = shows.body as IShowForm[];
        });
    }
  }

  submitTicketsForSale() {
    this.submitForm.markAllAsTouched();
    this.submitForm.updateValueAndValidity();
    if (this.submitForm.invalid) {
      this.showErrors = true;
    }

    if (this.submitForm.valid) {
      const data = this.submitForm.value;
      
      const success = this.ticketsService.postAvailableTickets(data)
        .pipe(
          takeUntil(this.destroy$)
        )  
        .subscribe(
          (res) => {
            console.log(res);

            console.log(JSON.stringify(res, null, 2));
            if (res['error']) {
              this.showErrors =true;
              this.submitForm.setErrors(res['error']);
            }
            if (res['message']){
              this.showSuccess = res['message'];
              this.submitForm.reset();
            }
          },
          (error: HttpErrorResponse) => {
            this.showErrors;
            this.submitForm.setErrors([JSON.stringify(error)]);
          }
        )
    }
  }

  submitSelectedTicket() {
    this.ticketSelectForm.markAllAsTouched();
    this.ticketSelectForm.updateValueAndValidity();
    if (this.ticketSelectForm.invalid) {
      this.showErrors = true;
    }

    if (this.ticketSelectForm.valid) {
      const data: ITicketClaim = this.ticketSelectForm.value;
      
      this.ticketsService.postClaimTicket(data)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(
          (res) => {
            console.log(res);

            console.log(JSON.stringify(res, null, 2));
            if (res['error']) {
              this.showErrors =true;
              this.ticketSelectForm.setErrors(res['error']);
            }
            if (res['message']){
              this.showSuccess = res['message'];
              this.ticketSelectForm.reset();
            }
          },
          (error: HttpErrorResponse) => {
            this.showErrors;
            this.ticketSelectForm.setErrors([JSON.stringify(error)]);
          }
        )
    }
  }

  selectTicket(ticket: ITicket) {
    this.showErrors = false;
    this.showSuccess = false;
    this.showTicketSelectForm = true;
    this.ticketSelectForm.get('id').setValue(ticket.id);
    this.ticketSelectForm.get('showData').setValue(ticket.formName);
    this.ticketSelectForm.get('publishedPath').setValue(ticket.publishedPath);
    this.ticketSelectForm.get('numberOfTickets').setValue(ticket.numberOfTickets);
  }

  backToTicketsView() {
    this.showTicketSelectForm = false;
    this.ticketSelectForm.reset();
  }
}
