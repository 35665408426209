import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roof-widget',
  templateUrl: './roof-widget.component.html',
  styleUrls: ['./roof-widget.component.scss']
})
export class RoofWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
