import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ShowComponent } from './components/show/show.component';
import { ConnectComponent } from './components/connect/connect.component';
import { FaqComponent } from './components/faq/faq.component';
import { RoofComponent } from './components/roof/roof.component';
import { PlayAtBarnComponent } from './components/play-at-barn/play-at-barn.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { DesignersComponent } from './components/designers/designers.component';
import { SelectedDesignerComponent } from './components/selected-designer/selected-designer.component';
import { PastShowsComponent } from './components/past-shows/past-shows.component';
import { PhotographersComponent } from './components/photographers/photographers.component';
import { TicketSwapComponent } from './components/ticket-swap/ticket-swap.component';
import { EnableTicketComponent } from './components/enable-ticket/enable-ticket.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'faq', component: FaqComponent},
  { path: 'connect', component: ConnectComponent},
  { path: 'roof', component: RoofComponent},
  { path: 'play-at-barn', component: PlayAtBarnComponent},
  { path: 'past-shows', component: PastShowsComponent },
  { path: 'show/:id', component: ShowComponent},
  { path: 'our-team', component: OurTeamComponent},
  { path: 'designers', component: DesignersComponent},
  { path: 'photographers', component: PhotographersComponent},
  { path: 'designers/:id', component: SelectedDesignerComponent},
  { path: 'ticket-swap', component: TicketSwapComponent},
  { path: 'enable-ticket/:guid', component: EnableTicketComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
