import { Component, OnInit, OnDestroy } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  randomNumber: string;
  opacity;
  constructor() { }

  ngOnInit() {
    this.opacity = '0';
    this.randomNumber = Math.floor(Math.random() * 41).toString();
  }

  getImageUrl() {
    return `assets/images/backgrounds/dark/${this.randomNumber}.jpg`;
  }

  fadeIn(event) {
    // this.opacity = '1.0';
    document.getElementById('shitty-hero').style.opacity = '1';
  }

  isBeforeSeasonAnnounce() {
    const today = new Date().toISOString();
    return today < '2025-03-21T04:00:00.000Z';
  }

  isBeforeJulyAnnounce() {
    const today = new Date().toISOString();
    return today < '2025-07-1T04:00:00.000Z';
  }
}
