import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export interface ITicket {
  id: string;
  displayId: string;
  formId: string;
  formName: string;
  firstName: string;
  numberOfTickets: string;
  publishedPath: string;
}

export interface ITicketClaim {
  displayId: string;
  showData: string;
  numberOfTickets: number;
  buyerEmail: string;
  buyerFirstName: string;
  buyerLastName: string;
}

export interface IShowForm {id: number; name: string; status: string; }


@Injectable({
  providedIn: 'root'
})
export class TicketsService {


  constructor(private http: HttpClient) { }


  getAvailableForms() {
    return this.http.get(`${environment.apiHost}/api/getAvailableForms.php`, { observe: 'response' });
  }

  getAvailableTickets() {
    return this.http.get(`${environment.apiHost}/api/getTicketsForSale.php`, { observe: 'response' });
  }

  postAvailableTickets(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };
    return this.http.post(`${environment.apiHost}/api/setTicketsForSale.php`, data, httpOptions);
  }

  setTicketTransferAvailable(guid) {
    return this.http.get(`${environment.apiHost}/api/setTicketTransferAvailable.php?g=${guid}`);
  }

  postClaimTicket(data: ITicketClaim) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };
    return this.http.post(`${environment.apiHost}/api/claimTicketForSale.php`, data, httpOptions);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    let message = 'Something bad happened; please try again later.'
    if (error?.error?.text) {
      message = error.error.text;
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(message));
  }
}