import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {TooltipModule} from 'ng2-tooltip-directive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { UpcomingShowsComponent } from './components/upcoming-shows/upcoming-shows.component';
import { PastShowsComponent } from './components/past-shows/past-shows.component';
import { ShowComponent } from './components/show/show.component';
import { HttpClientModule } from '@angular/common/http';
import { ShowsService } from './services/shows.service';
import { FaqComponent } from './components/faq/faq.component';
import { ConnectComponent } from './components/connect/connect.component';
import { MailchimpComponent } from './components/mailchimp/mailchimp.component';
import { FeaturedShowsComponent } from './components/upcoming-shows/featured-shows/featured-shows.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { initialState } from './state/initialState';
import { effects } from './state/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ChairComponent } from './components/icons/chair/chair.component';
import { SpeakerComponent } from './components/icons/speaker/speaker.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ShowCardComponent } from './components/show-card/show-card.component';
import { RoofComponent } from './components/roof/roof.component';
import { RoofWidgetComponent } from './components/roof/roof-widget/roof-widget.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PlayAtBarnComponent } from './components/play-at-barn/play-at-barn.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { DesignersComponent } from './components/designers/designers.component';
import { SelectedDesignerComponent } from './components/selected-designer/selected-designer.component';
import { PhotographersComponent } from './components/photographers/photographers.component';
import { TicketSwapComponent } from './components/ticket-swap/ticket-swap.component';
import { TicketsService } from './services/tickets.service';
import { EnableTicketComponent } from './components/enable-ticket/enable-ticket.component';

const developmentImports = [
  StoreDevtoolsModule.instrument({
    maxAge: 100,
  }),
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UpcomingShowsComponent,
    PastShowsComponent,
    ShowComponent,
    FaqComponent,
    ConnectComponent,
    MailchimpComponent,
    FeaturedShowsComponent,
    SidebarComponent,
    ChairComponent,
    SpeakerComponent,
    ShowCardComponent,
    RoofComponent,
    RoofWidgetComponent,
    PlayAtBarnComponent,
    OurTeamComponent,
    DesignersComponent,
    SelectedDesignerComponent,
    PhotographersComponent,
    TicketSwapComponent,
    EnableTicketComponent
  ],
  imports: [
    LazyLoadImageModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TooltipModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, {
      initialState,
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot(effects),
    ...developmentImports,
  ],
  providers: [ShowsService, TicketsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
