<div id="shittyCarousel" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#shittyCarousel" data-slide-to="0" class="active"></li>
    <!-- <li data-target="#shittyCarousel" *ngFor="let show of (shows$ | async); index as i;" [ngClass]="{'active': i === 0}" data-slide-to="{i + 1}"></li> -->
    <!-- <li data-target="#shittyCarousel" *ngFor="let show of (shows$ | async); index as i;"  data-slide-to="{i + 2}"></li> -->
  </ol>
  <div class="carousel-inner translucent-bg">
    <div class="carousel-item active featured-image">
      <div class="image-wrapper">
        <img src="assets/images/backgrounds/dark/{{randomNumber}}.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
        <img src="assets/images/theshittybarnsessions.png" class="d-block w-100" alt="the shitty barn sessions" />
        <!-- <h5>Second slide label</h5> -->
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
      </div>
    </div>
    <!-- <div class="carousel-item" *ngFor="let show of (shows$ | async); index as i;" [ngClass]="{'active': i === 0}"> -->
    <!-- <div class="carousel-item" *ngFor="let show of (shows$ | async); index as i;">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="featured-copy">
            <h2 class="h5">No. {{ show.SessionNumber }} | {{show.Date | date: 'mediumDate' }}</h2>
            <h3 class="h1">{{show.Title}}</h3>
            <h4 class="h1">${{show.TicketPrice}}</h4>
            <h5 class="card-subtitle mb-2 text-muted">{{show.StartTime}}</h5>
            <p class="card-text">{{show.Excerpt}}</p>
            <div class="speaker-chair-icons">
              <app-speaker *ngIf="['2', '3', '4'].indexOf(show.Loudness) !== -1"></app-speaker>
              <app-chair *ngIf="show.StandingOnly === '1'"></app-chair>
            </div>
            <a class="btn btn-primary" [routerLink]="['/show', show.ID]">More Info</a>
            <a href="{{show.TicketUrl}}" target="_blank" class="btn btn-primary" *ngIf="show.TicketUrl">Buy Tickets</a>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <img src="{{show.featuredImageOrPoster}}" class="d-block w-100" alt="show.Title">
        </div>
      </div>
    </div> -->
  </div>
  <!-- <a class="carousel-control-prev" href="#shittyCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#shittyCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a> -->
</div>