import { Component } from '@angular/core';

@Component({
  selector: 'app-rogue-sessions',
  templateUrl: './rogue-sessions.component.html',
  styleUrls: ['./rogue-sessions.component.scss']
})
export class RogueSessionsComponent {

}
