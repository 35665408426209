<div class="card bg-dark" [class.rogue]="isRogue(show)" *ngIf="show && display === 'grid'">
  <a [routerLink]="['/show', show.ID]" class="showPoster image-link rogue-background">
    <img class="card-img-top" alt="{{show.Title}}" defaultImage="assets/images/default.png" [lazyLoad]="show.featuredImageOrPoster" offset="800">
    <span class="sessionNumber overlay-box"><span *ngIf="isRogue(show)">Rogue Session</span><span *ngIf="!isRogue(show)">no.</span> {{ show.SessionNumber }}</span>
    <span class="noChairs overlay-box" *ngIf="isRogue(show)">No chairs! Just dancing!</span>
    <span *ngIf="!past" class="price overlay-box">${{show.TicketPrice}}</span>
    <span class="hover-overlay"></span>
  </a>
  <div class="card-body">
    <h5 class="card-title"><a class="white-anchor" [routerLink]="['/show', show.ID]">{{show.Title}}</a></h5>
    <h6 class="card-subtitle mb-2 text-muted">{{show.Date | date: 'mediumDate' }}<span *ngIf="!past"> | {{show.StartTime}}</span></h6>
    <p class="card-text">{{show.Excerpt}}</p>
    <div class="speaker-chair-icons">
      <!-- <app-speaker *ngIf="isRogue(show)"></app-speaker> -->
      <a class="rogueLinkVerbose" *ngIf="isRogue(show)" [routerLink]="['/rogue-sessions']" target="_blank">What is a "Rogue" show?</a>

      <app-chair *ngIf="show.StandingOnly === '1'"></app-chair>
    </div>
    <div *ngIf="!past" class="row justify-content-between">
      <div class="col-12">
        <a href="{{show.TicketUrl}}" target="_blank" class="btn btn-primary" *ngIf="show.TicketUrl && show.Soldout !== '1'">Buy Tickets</a>
        <span *ngIf="show.Soldout === '1'">SOLD OUT</span>
      </div>
    </div>
  </div>
</div>

<div class="bg-dark row show-list-item" [class.rogue]="isRogue(show)" *ngIf="show && display === 'list'">
  <a [routerLink]="['/show', show.ID]" class="showPoster image-link col-sm-12 col-md-3 rogue-background">
    <img class="card-img-top" alt="{{show.Title}}" defaultImage="assets/images/default.png" [lazyLoad]="show.featuredImageOrPoster" offset="800">
    <span class="noChairs overlay-box" *ngIf="isRogue(show)">No chairs! Just Dancing!</span>
    <span class="hover-overlay"></span>
  </a>
  <div class="col-sm-12 col-md-9">
    <h3 class="card-title h2"><a class="white-anchor" [routerLink]="['/show', show.ID]"><span class="rogueLink" *ngIf="isRogue(show)">Rogue</span> {{ show.SessionNumber }}: {{show.Title}}</a>
    <!-- <span class="list-ticket-price">${{show.TicketPrice}}</span> -->
    </h3>
    <h4 class="card-subtitle mb-2 text-muted h3">{{show.Date | date: 'mediumDate' }}<span *ngIf="!past"> | {{show.StartTime}}</span></h4>

    <p class="card-text">{{show.Excerpt}}</p>
    <div class="speaker-chair-icons">
      <a class="rogueLinkVerbose" *ngIf="isRogue(show)" [routerLink]="['/rogue-sessions']" target="_blank">What is a "Rogue" show?</a>
      <!-- <app-speaker *ngIf="isRogue(show)"></app-speaker> -->
      <app-chair *ngIf="show.StandingOnly === '1'"></app-chair>
    </div>
    <div class="row justify-content-between">
      <!-- <div class="col-6">
        <a class="btn btn-primary" [routerLink]="['/show', show.ID]">More Info</a>
      </div> -->
      <div class="col-12" *ngIf="!past">
        <div *ngIf="show.Soldout !== '1'">
          <span class="list-ticket-price h1">${{show.TicketPrice}}</span>
          <span *ngIf="show.TicketUrl"><span class="h1"> | </span><a href="{{show.TicketUrl}}" target="_blank" class="btn btn-primary" style="margin-top: -20px;">Buy Tickets</a>
          </span>
        </div>
        <span *ngIf="show.Soldout === '1'" class="list-ticket-price h1">SOLD OUT</span>
      </div>
    </div>
  </div>
</div>