import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { Store } from '@ngrx/store';
import { FetchUpcomingShows } from './state/shows/shows';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'shitty-ui';
  activeLink;
  currentUrl = '/';

  constructor(
    private store$: Store<any>,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd ) {
        this.currentUrl = event.url;
        window.scrollTo(0, 0);

        const nav = document.getElementById('navbarSupportedContent');
        nav.classList.remove('show');
      }
    });
    this.store$.dispatch(new FetchUpcomingShows());
  }

  scrollToFooter() {
    const el = document.getElementById('footer');
    el.scrollIntoView({behavior: 'smooth'});
  }

  getYear() {
    return dayjs().format('YYYY');
  }

  setActiveLink(link) {
    this.activeLink = link;
    if (link !== 'contact') {
      window.scrollTo(0, 0);
    }
  }
}
