<div class="row">
  <div class="col-lg-12 col-xl-7 offset-xl-1 translucent-bg simple-page">
    <h1>How do I get my band to play the Barn?</h1>
    <div class="content">
      <p>So your band wants to play a Sh*tty Barn Session? We can’t blame you, it’s an amazing space!  We host bands from all around the world with all different musical styles. However, we are a seasonal venue with limited slots available, offering 30-40 curated dates from May to mid-October (old WI barn, no heat!) and only 1-2 shows per week.</p>
      <p>We are flattered to receive a multitude of solicitations from bands every year and also have many of our own favorites that we really want to host at the Barn. We do try to listen to everyone that reaches out but it can be very difficult to keep up sometimes so sorry if we take a while to respond.</p>
      <p>With that said, if you would like to submit your band for consideration, please email: <a href="mailto:booking@shittybarnsessions.com">booking@shittybarnsessions.com</a></p>

      <p>The following info is helpful too:</p>
      <ul>
        <li>
          Social Media Links (Website, Facebook, Instagram, Twitter)
        </li>
        <li>
        Music & Video Links (Bandcamp, Soundcloud, Spotify)
        </li>
        <li>
        Date(s) requested
        </li>
      </ul>

      <p>Thank you so much for your interest! We’ll see you out there.</p>
    </div>
  </div>
  <div class="col-lg-12 col-xl-3 sidebar">
      <app-sidebar></app-sidebar>
    </div>
</div>


