import { IPayloadAction } from '../payloadAction';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { ShowsService } from '../../services/shows.service';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { switchMap, withLatestFrom, catchError, mergeMap } from 'rxjs/operators';
import { getOldOrNewFile } from '../../util/fileUrl';

export const NOOP = '[team] noop';
export const FETCH_TEAM = '[team] fetch';
export const FETCH_TEAM_SUCCESS = '[team] fetch success';

export class NoOp implements IPayloadAction {
  readonly type = NOOP;
}

export class FetchTeam implements IPayloadAction {
  readonly type = FETCH_TEAM;
}

export class FetchTeamSuccess implements IPayloadAction {
  readonly type = FETCH_TEAM_SUCCESS;
  constructor(public payload: any) { }
}

export interface ITeamMember {
  name?: string;
  role?: string;
  bio?: string;
  url?: string;
  image?: string;
}

export interface ITeam {
  owners?: ITeamMember[];
  staff?: ITeamMember[];
  heros?: ITeamMember[];
  photographers?: ITeamMember[];
}

export const initialTeamState: ITeam = {};

export function teamReducer(initialTeamState: any, action: IPayloadAction) {
  switch (action.type) {
    case FETCH_TEAM_SUCCESS:
      return action.payload;
    default: {
      return initialTeamState;
    }
  }
}

@Injectable()
export class teamEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private showsService: ShowsService,
  ) {
  }

  fetchTeam$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_TEAM),
    withLatestFrom(
      this.store$.select('team')
    ),
    switchMap(([action, team]: [IPayloadAction, ITeam]) => {
      // return NoOp if request to fetch team is already in state.
      if (team && team.owners) {
        return of(new NoOp());
      }

      const getTeamRequest = this.showsService.getStaff();

      return from(getTeamRequest).pipe(
        mergeMap((result: any) => {
          const owners = [];
          const staff = [];
          const heros = [];
          const photographers = [];
          result.body.forEach((t) => {
            if (t.Owner === '1') {
              owners.push(formatTeamMember(t));
            }
            if (t.Staff === '1') {
              staff.push(formatTeamMember(t));
            }
            if (t.Hero === '1') {
              heros.push(formatTeamMember(t));
            }
            if (t.Photographer === '1') {
              photographers.push(formatTeamMember(t));
            }
          });
          return of(
            new FetchTeamSuccess({
              owners,
              staff,
              heros,
              photographers,
            }),
          );
        }),
        catchError((error) => of(new NoOp()))
      );
    }),
  ));
}

function formatTeamMember(member): ITeamMember {
  return {
    name: member.Name,
    role: member.Role,
    bio: member.Bio,
    url: member.url,
    image: getOldOrNewFile(member.ImageName, member.ImageHash, member.OldImageName, 'Uploads', true)
  }
}
