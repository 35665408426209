<div class="row">
  <div class="col-lg-12 col-xl-7 offset-xl-1 translucent-bg simple-page">
    <h1>Frequently Asked Questions</h1>
    <div class="content">
      <div class="accordion" id="accordionExample">
        <div class="card bg-dark" *ngFor="let faq of faqs; index as i">
          <div class="card-header" id="heading{{i}}">
            <h2 class="h5">
              <button class="btn btn-link" [ngClass]="{'collapsed': itemOpen !== i, 'expanded': itemOpen === i}"  (click)="openItem(i)" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span class="openCloseIcon" *ngIf="itemOpen === i">-</span>
                <span class="openCloseIcon" *ngIf="itemOpen !== i">+</span>
                 {{ faq.question}}
              </button>
            </h2>
          </div>
          <div id="collapse{{i}}" class="collapse show" [ngClass]="{'show': itemOpen === i}" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body" [innerHTML]="faq.answer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-xl-3 sidebar">
    <app-sidebar></app-sidebar>
  </div>
</div>