<header class="fixed-top">
  <!-- <div class="bg-dark text-center">
    <img src="/assets/images/sbsname.jpg" alt="the shitty barn sessions" />
  </div> -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark text-center">
    <a class="navbar-brand" [routerLink]="['/']"><img src="/assets/images/theshittybarn.jpg" alt="the shitty barn" width="200" /></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [ngClass]="{'active': currentUrl ==='/' || activeLink === 'home'}">
          <a class="nav-link" [routerLink]="['/']" (click)="setActiveLink('home')">Home <span class="sr-only" *ngIf="currentUrl === '/'">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Info
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" [routerLink]="['/faq']" (click)="setActiveLink('faq')">FAQ <span class="sr-only" *ngIf="currentUrl === '/faq'">(current)</span></a>
                <a class="dropdown-item" [routerLink]="['/play-at-barn']" (click)="setActiveLink('play-at-barn')">Play at the barn <span class="sr-only" *ngIf="currentUrl === '/play-at-barn'">(current)</span></a>
              </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Our team
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/our-team']" (click)="setActiveLink('our-team')">Staff <span class="sr-only" *ngIf="currentUrl === '/our-team'">(current)</span></a>
              <a class="dropdown-item" [routerLink]="['/designers']" (click)="setActiveLink('designers')">Designers <span class="sr-only" *ngIf="currentUrl === '/designers'">(current)</span></a>
              <a class="dropdown-item" [routerLink]="['/photographers']" (click)="setActiveLink('photographers')">Photographers <span class="sr-only" *ngIf="currentUrl === '/photographers'">(current)</span></a>
            </div>
        </li>
        <li class="nav-item" [ngClass]="{'active': currentUrl ==='/past-shows' || activeLink === 'past-shows'}">
          <a class="nav-link" [routerLink]="['/past-shows']" (click)="setActiveLink('past-shows')">Past shows <span class="sr-only" *ngIf="currentUrl === '/past-shows'">(current)</span></a>
        </li>
        <li class="nav-item" [ngClass]="{'active': currentUrl ==='/ticket-swap' || activeLink === 'ticket-swap'}">
          <a class="nav-link" [routerLink]="['/ticket-swap']" (click)="setActiveLink('ticket-swap')">Ticket Swap <span class="sr-only" *ngIf="currentUrl === '/ticket-swap'">(current)</span></a>
        </li>
        <!-- <li class="nav-item mobile-and-tablet" [ngClass]="{'active': currentUrl === '/roof' || activeLink ==='roof'}">
          <a class="nav-link" [routerLink]="['/roof']" (click)="setActiveLink('roof')">Our New Roof <span class="sr-only" *ngIf="currentUrl === '/roof'">(current)</span></a>
        </li> -->
        <!-- <li class="nav-item dropdown desktop-only">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              2020 Spotify Playlist
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <iframe src="https://open.spotify.com/embed/playlist/1P23QXYqhv0RPofLfVj1sv" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
        </li> -->
        <li class="nav-item" [ngClass]="{'active': activeLink ==='contact'}">
          <a class="nav-link" (click)="setActiveLink('contact'); scrollToFooter()">Contact</a>
        </li>
      </ul>
      <nav class="my-2 my-md-0 mr-md-3 navbar-dark bg-dark social-icons">
          <a class="p-2 text-dark" href="https://www.facebook.com/barnsessions" target="_blank"><img src="assets/images/facebook.png" alt="facebook" /></a>
          <a class="p-2 text-dark" href="https://twitter.com/BarnSessions" target="_blank"><img src="assets/images/twitter.png" alt="twitter" /></a>
          <a class="p-2 text-dark" href="https://www.instagram.com/barnsessions/" target="_blank"><img src="assets/images/instagram.png" alt="instagram" /></a>
        </nav>
    </div>
  </nav>
</header>

<div id="main" class="container-fluid dark-bg">

  <router-outlet></router-outlet>
</div>

<footer id="footer" class="container-fluid">
<div class="row">
    <div class="col-sm-12 col-md-4 sidebar-widget mb-4">
      <h2 class="h3">Subscribe to our mailing list</h2>
      <app-mailchimp></app-mailchimp>
    </div>
    <div class="col-sm-12 col-md-4 sidebar-widget mb-4">
      <h2 class="h3">Find us</h2>
      <p>506 E Madison St, Spring Green, WI 53588</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d372437.4835759448!2d-90.062226!3d43.174496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa2ce9e0f8e67660e!2sThe+Shitty+Barn!5e0!3m2!1sen!2sus!4v1452199737994" width="100%" height="320" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>
    <div class="col-sm-12 col-md-4 sidebar-widget mb-4">
      <h2 class="h3">Contact</h2>
      <ul class="list-group">
        <li class="list-group-item bg-dark">General: <a href="mailto:info@shittybarnsessions.com">info@shittybarnsessions.com</a></li>
        <li class="list-group-item bg-dark">Ticketing: <a href="mailto:tickets@shittybarnsessions.com">tickets@shittybarnsessions.com</a></li>
        <li class="list-group-item bg-dark">Booking: <a href="mailto:booking@shittybarnsessions.com">booking@shittybarnsessions.com</a></li>
        <li class="list-group-item bg-dark">Media: <a href="mailto:media@shittybarnsessions.com">media@shittybarnsessions.com</a></li>
      </ul>
    </div>
  </div>
  <p class="text-center">&copy;{{getYear()}} &sdot; <a [routerLink]="['/']">home</a> &sdot; <a [routerLink]="['/faq']">faq</a></p>
</footer>
