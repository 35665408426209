import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-featured-shows',
  templateUrl: './featured-shows.component.html',
  styleUrls: ['./featured-shows.component.scss']
})
export class FeaturedShowsComponent implements OnInit {
  randomNumber: string;
  constructor() { }

  ngOnInit() {
    this.randomNumber = Math.floor(Math.random() * 28).toString();
  }
}
