import { Component, OnInit, OnDestroy } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  randomNumber: string;
  opacity;
  constructor() { }

  ngOnInit() {
    this.opacity = '0';
    this.randomNumber = this.getWeightedRandomNumber(56).toString();
  }

  getWeightedRandomNumber(maxNumber: number) {
    let random = Math.random();
    
    // Apply a transformation to skew the random number towards higher numbers.
    // For example, we can use the square of the random value to bias it towards the higher range.
    let biasedRandom = Math.pow(random, 0.5); // Skewed to prefer higher values
    
    // Scale this transformed number to the range 0-56
    let weightedRandom = biasedRandom * maxNumber;

    // Round to get a whole number
    weightedRandom = Math.round(weightedRandom);

    // If the number is below 42, we don't adjust it; it naturally will fall between 0-41
    // But if it's above 41, the transformation ensures it has a higher chance of being closer to 56.

    return weightedRandom;
}

  getImageUrl() {
    return `assets/images/backgrounds/dark/${this.randomNumber}.jpg`;
  }

  fadeIn(event) {
    // this.opacity = '1.0';
    document.getElementById('shitty-hero').style.opacity = '1';
  }

  isBeforeSeasonAnnounce() {
    const today = new Date().toISOString();
    return today < '2025-03-24T04:00:00.000Z';
  }

  isBeforeJulyAnnounce() {
    const today = new Date().toISOString();
    return today < '2025-07-1T04:00:00.000Z';
  }
}
