<div class="posters row" *ngIf="(posters$ | async) as posters">
  <div class="offset-1 col-10">
    <h1>Designers</h1>
    <p class="mb-4">Art is the heart of the barn. Our team of designers and illustrators donate their talent and time to design custom gig posters to make each of our shows special for the musicians and audience. We are ever in their debt.</p>
    <div class="row">
      <div class="poster col-xs-12 col-sm-6 col-lg-4 mb-4" (click)="getDesigner(designer)" *ngFor="let designer of (designers$ | async)">
        <h3>{{ designer.name }}</h3>
        <img src="{{posters[designer.name] && posters[designer.name][0] && posters[designer.name][0].url}}" alt="{{designer.name}}" />
      </div>
    </div>
  </div>
</div>