<div *ngIf="!(showsByMonth$ | async).length" class="mt-4">
  <!-- <h1>2020 Season will be announced when we have more information about when we can start our season. Stay tuned.</h1> -->
  <!--
    <p>Dang, that was a great season!
    <br />
    And you can bet there’s more to come. Watch for our new schedule coming out in March (and be sure to check out our friends, The Slowpoke Lounge, in Spring Green to see some fine bands playing when we’re closed!).</p>
  -->
</div>
<div *ngIf="(showsByMonth$ | async).length" class="mt-4">
  <h2 id="upcomingShows" class="h1">Upcoming Shows
    <a class="display-toggle tablet-only" (click)="setDisplay('grid')" tooltip="Display shows in grid format" [options]="{placement: 'top', theme: 'light', 'show-delay': 250, 'hide-delay': 250}">
      <svg class="bi bi-grid-fill" width="1em" height="1em" viewBox="0 0 20 20" fill="#fbf6eb" xmlns="http://www.w3.org/2000/svg">
        <rect width="6" height="6" x="3.5" y="10.5" rx="1"/>
        <rect width="6" height="6" x="10.5" y="10.5" rx="1"/>
        <rect width="6" height="6" x="10.5" y="3.5" rx="1"/>
        <rect width="6" height="6" x="3.5" y="3.5" rx="1"/>
      </svg>
    </a>
    <a class="display-toggle tablet-only" (click)="setDisplay('list')" tooltip="Display shows as a list" [options]="{placement: 'top', theme: 'light', 'show-delay': 250, 'hide-delay': 250}">
      <svg class="bi bi-list" width="1em" height="1em" viewBox="0 0 20 20" fill="#fbf6eb" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M4.5 13.5A.5.5 0 015 13h10a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-4A.5.5 0 015 9h10a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-4A.5.5 0 015 5h10a.5.5 0 010 1H5a.5.5 0 01-.5-.5z" clip-rule="evenodd"/>
      </svg>
    </a>
  </h2>
  <p class="text-muted">Heads up, you can purchase up to 6 tickets per show per transaction. Tickets to each show must be purchased as separate transactions. Prioritize your choices!</p>


  <div class="row" *ngFor="let month of (showsByMonth$ | async)">
    <div class="col-12">
      <h3 class="h1"><span class="badge badge-danger">{{month.month }}</span></h3>
    </div>
    <div [ngClass]="{'col-xs-12 col-sm-6 col-lg-4': display === 'grid', 'col-12': display ==='list'}" *ngFor="let show of month.shows">
      <app-show-card [show]="show" [display]="display"></app-show-card>
    </div>
  </div>

  <nav class="nav nav-pills bg-dark fixed-bottom shows-filter">
    <span class="filter-label">Filter shows by month:</span>
      <a class="nav-item nav-link active desktop-only" (click)="selectMonth('all')" [ngClass]="{'active': (selectedMonth$ | async) === 'all'}">All</a>
      <a class="nav-item nav-link desktop-only" *ngFor="let month of (months$ | async)" (click)="selectMonth(month)" [ngClass]="{'active': (selectedMonth$ | async) === month}">{{month}}</a>
      <div class="form-group nav-item mobile-and-tablet monthsForm">
        <form [formGroup]="monthsForm">
        <select class="form-control" id="exampleFormControlSelect1" formControlName="month">
          <option ngValue="all">all</option>
          <option *ngFor="let month of (months$ | async)" [ngValue]="month" >{{month}}</option>
        </select>
        </form>
      </div>
      <div class="desktop-only display-type-separator"> </div>
      <div class="tablet-only footer-display-type">
        <span class="filter-label">Display shows as:</span>
        <a class="nav-item nav-link display-toggle tablet-only sb-font" [ngClass]="{'active': display === 'grid'}" (click)="setDisplay('grid')">Grid</a>
            <span class="display-toggle"> | </span>
        <a class="nav-item nav-link display-toggle tablet-only sb-font" [ngClass]="{'active': display === 'list'}" (click)="setDisplay('list')">List</a>
      </div>
  </nav>
</div>

    <!--
    ID: "267"
  Title: "Nwa Na Agbe"
  Date: "2019-05-01"
  Excerpt: null
  StartTime: "Doors: 6pm
  ↵Show: 7pm"
  Loudness: "1"
  VisibleOn: "2019-03-28"
  Featured: "1"
  TicketPrice: "15"
  DiscountPrice: "12"
  TicketUrl: "https://www.brownpapertickets.com/event/4197696"
PosterName: "shittybarn nwa na agbe SOCIAL"
PosterUrl: "assets/Uploads/shittybarn-nwa-na-agbe-SOCIAL.jpg"
FeaturedImageName: "29597554 160104721333557 5055024498836850417 n2"
FeaturedImageUrl: "assets/Uploads/29597554-160104721333557-5055024498836850417-n2.jpg"
-->
