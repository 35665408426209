import { IPayloadAction } from '../payloadAction';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { ShowsService } from '../../services/shows.service';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { switchMap, withLatestFrom, catchError, mergeMap, map, tap, combineLatest, filter } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as uniq from 'lodash/uniq';
import { getOldOrNewFile, getPosterOrFeaturedImgUrl } from '../../util/fileUrl';
import cloneDeep from 'lodash/cloneDeep';

export const FETCH_SHOW = '[Show] fetch';
export const FETCH_SHOW_SUCCESS = '[Show] fetch success';

export class FetchShow implements IPayloadAction {
  readonly type = FETCH_SHOW;
  constructor(public payload: string) { }
}

export class FetchShowSuccess implements IPayloadAction {
  readonly type = FETCH_SHOW_SUCCESS;
  constructor(public payload: any) { }
}

export const initialCurrentShowState: any = {};
export const initialFullShowsState: any = {};

export function currentShowReducer(show: any, action: IPayloadAction) {
  switch (action.type) {
    case FETCH_SHOW_SUCCESS:
      return action.payload;
    default: {
      return show;
    }
  }
}

export function fullShowsReducer(shows: any, action: IPayloadAction) {
  switch (action.type) {
    case FETCH_SHOW_SUCCESS:
      // const showsClone = cloneDeep(shows);
      if (action.payload && action.payload.ID) {
        return { ...shows, [action.payload.ID]: action.payload};
      } else {
        return undefined;
      }
    default: {
      return shows;
    }
  }
}

@Injectable()
export class ShowEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private showsService: ShowsService,
  ) {
  }

  fetchShow$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_SHOW),
    withLatestFrom(this.store$.select('allShows')),
    mergeMap(([action, shows]: [IPayloadAction, any]) => {
      if (shows && shows[action.payload]) {
        return of(new FetchShowSuccess(shows[action.payload]));
      } else {
        const getShowRequest = this.showsService.getShow(action.payload);
        return from(getShowRequest).pipe(
          mergeMap((result: any) => {
            const show = result.body;
            const showEnhanced = {
              ...show,
              isPast: dayjs().isAfter(show.Date),
              PosterUrl: getOldOrNewFile(show.PosterName, show.PosterHash, show.PosterOldName, 'show-images'),
              FeaturedImageUrl1: getOldOrNewFile(show.FeaturedImageName, show.FeaturedImageHash, show.FeaturedImageOldName, 'show-images'),
              FeaturedImageUrl2: getOldOrNewFile(
                show.FeaturedImageName2,
                show.FeaturedImageHash2,
                show.FeaturedImageOldName2,
                'show-images'
              ),
            };
            return of(
              new FetchShowSuccess(showEnhanced),
            );
          })
        );
      }
    }),
  ));
}
