import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FetchTeam } from '../../state/staff/ourTeam';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-photographers',
  templateUrl: './photographers.component.html',
  styleUrls: ['./photographers.component.scss']
})
export class PhotographersComponent implements OnInit {
  photographers$: Observable<any[]>;
  constructor(
    private store$: Store<any>,
  ) { }

  ngOnInit() {
    this.store$.dispatch(new FetchTeam());
    this.photographers$ = this.store$.select('team', 'photographers');
  }

}
