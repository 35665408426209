import { Component, OnInit } from '@angular/core';
import { ShowsService } from '../../services/shows.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs: any;
  itemOpen;

  constructor(private service: ShowsService) { }

  ngOnInit() {
    this.service.getFaq().subscribe((faqs: any) => {
      this.faqs = faqs.body;
    });
  }

  openItem(i) {
    if (this.itemOpen === i) {
      this.itemOpen = -1;
    } else {
      this.itemOpen = i;
    }
  }

}
