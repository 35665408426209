<div class="row">
  <div class="col-12">
    <h1>Connect</h1>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4">
    <h2>Contact</h2>
    <ul class="list-group">
      <li class="list-group-item bg-dark">General: <a href="mailto:info@shittybarnsessions.com">info@shittybarnsessions.com</a></li>
      <li class="list-group-item bg-dark">Media: <a href="mailto:media@shittybarnsessions.com">media@shittybarnsessions.com</a></li>
      <li class="list-group-item bg-dark">Media: <a href="mailto:booking@shittybarnsessions.com">booking@shittybarnsessions.com</a></li>
    </ul>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4">
    <h2>Find us</h2>
    <p>506 E Madison St, Spring Green, WI 53588</p>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d372437.4835759448!2d-90.062226!3d43.174496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa2ce9e0f8e67660e!2sThe+Shitty+Barn!5e0!3m2!1sen!2sus!4v1452199737994" width="320" height="320" frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4">
    <h2>Subscribe to our mailing list</h2>
    <app-mailchimp></app-mailchimp>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4">
    <h3>Follow us</h3>
    <a href="http://www.facebook.com/barnsessions" target="_blank"><img src="/assets/images/facebook.png" alt="facebook" /></a>
    <a href="http://twitter.com/#!/BarnSessions" target="_blank"><img src="/assets/images/twitter.png" alt="twitter" /></a>
    <a href="https://www.instagram.com/barnsessions/" target="_blank"><img src="/assets/images/instagram.png" alt="instagram" /></a>
  </div>
</div>
