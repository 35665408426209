import { initialUpcomingShowsState, initialFeaturedShowsState, initialMonthsState, initialSelectedMonthState, initialPastShowsState, initialShowsByYear, initialSelectedYearState } from './shows/shows';
import { initialCurrentShowState, initialFullShowsState } from './show/show';
import { initialTeamState } from './staff/ourTeam';
import { initialPostersState, initialDesignerState, initialSelectedDesignerState } from './staff/designers';

export interface ApplicationState {
  upcomingShows: any;
  featuredShows: any;
  months: any;
  selectedMonth: string;
}

export const initialState = {
  upcomingShows: initialUpcomingShowsState,
  featuredShows: initialFeaturedShowsState,
  months: initialMonthsState,
  selectedMonth: initialSelectedMonthState,
  selectedYear: initialSelectedYearState,
  currentShow: initialCurrentShowState,
  allShows: initialFullShowsState,
  team: initialTeamState,
  posters: initialPostersState,
  designers: initialDesignerState,
  selectedDesigner: initialSelectedDesignerState,
  pastShows: initialPastShowsState,
  showsByYear: initialShowsByYear,
};
