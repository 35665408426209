import { environment } from '../../environments/environment';

export function getOldOrNewFile(fileName, fileHash = null, oldImage = false, filePath = 'Uploads', thumbnail = false) {
  const host = environment.assetsHost;
  if (!fileName) {
    return '';
  }
  const splitFileName = fileName.split('.');
  if (oldImage) {
    return `${host}/${oldImage}`;
  } else {
    // return `${host}/assets/${filePath}/${fileHash.substring(0, 10)}/${splitFileName[0]}__FitMaxWzkzMCwzMzZd.${splitFileName[1]}`;
    if (thumbnail) {
      return `${host}/assets/${filePath}/${constructName(splitFileName)}__FitMaxWzM1MiwyNjRd.${splitFileName[splitFileName.length - 1]}`;
    } else {
      return `${host}/assets/${filePath}/${constructName(splitFileName)}.${splitFileName[splitFileName.length - 1]}`;
    }
  }
}

export function constructName(splitFileName: string[]) {
  let name = splitFileName[0];
  for (let i = 1; i < (splitFileName.length -1); i++) {
    name += `.${splitFileName[i]}`;
  }
  return name;
}

export function getPosterOrFeaturedImgUrl(show) {
  if (show.PosterName) {
    return getOldOrNewFile(show.PosterName, show.PosterHash, show.PosterOldName, 'show-images');
  } else if (show.FeaturedImageName) {
    return getOldOrNewFile(show.FeaturedImageName, show.FeaturedImageHash, show.FeaturedImageOldName, 'show-images');
  }
  return false;
}

export function getPosterOrFeaturedImgThumbUrl(show) {
  if (show.PosterName) {
    return getOldOrNewFile(show.PosterName, show.PosterHash, show.PosterOldName, 'show-images', true);
  } else if (show.FeaturedImageName) {
    return getOldOrNewFile(show.FeaturedImageName, show.FeaturedImageHash, show.FeaturedImageOldName, 'show-images', true);
  }
  return false;
}
