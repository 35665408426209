import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { ApplicationState } from './initialState';
import { environment } from '../../environments/environment';
import { upcomingShowsReducer, selectedMonthReducer, monthsReducer, featuredShowsReducer, pastShowsReducer, showsByYearReducer, yearsReducer, selectedYearReducer } from './shows/shows';
import { currentShowReducer, fullShowsReducer } from './show/show';
import { teamReducer } from './staff/ourTeam';
import { designersReducer, selectedDesignerReducer, postersReducer } from './staff/designers';
export const metaReducers: MetaReducer<ApplicationState>[] = !environment.production ? [] : [];

export const reducers: ActionReducerMap<any> = {
  upcomingShows: upcomingShowsReducer,
  featuredShows: featuredShowsReducer,
  months: monthsReducer,
  selectedMonth: selectedMonthReducer,
  years: yearsReducer,
  selectedYear: selectedYearReducer,
  currentShow: currentShowReducer,
  allShows: fullShowsReducer,
  pastShows: pastShowsReducer,
  team: teamReducer,
  designers: designersReducer,
  selectedDesigner: selectedDesignerReducer,
  posters: postersReducer,
  showsByYear: showsByYearReducer,
};
