import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { SelectMonth } from '../../state/shows/shows';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-upcoming-shows',
  templateUrl: './upcoming-shows.component.html',
  styleUrls: ['./upcoming-shows.component.scss']
})
export class UpcomingShowsComponent implements OnInit, OnDestroy {
  shows: [];
  display = 'grid';
  showsByMonth$: Observable<[]>;
  months$: Observable<[]>;
  selectedMonth$: Observable<string>;
  showsByMonth: any = [];
  featuredShows: any;
  monthsForm: UntypedFormGroup;
  selectedMonth = 'all';
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<any>,
  ) { }
  ngOnInit() {
    this.display = (localStorage.getItem('sb-show-card-display')) ? localStorage.getItem('sb-show-card-display') : this.display;
    this.monthsForm = new UntypedFormGroup({
      month: new UntypedFormControl('all'),
    });
    this.subscriptions.push(
      this.monthsForm.get('month').valueChanges.subscribe((value) => {
        this.store$.dispatch(new SelectMonth(value));
        const el = document.getElementById('upcomingShows');
        el.scrollIntoView({behavior: 'smooth'});
      })
    );
    this.months$ = this.store$.select('months');
    this.selectedMonth$ = this.store$.select('selectedMonth');
    this.showsByMonth$ = combineLatest([
      this.store$.select('upcomingShows'),
      this.selectedMonth$
    ]).pipe(
      map(([shows, month]) => {
        if (month === 'all') {
          return shows;
        } else {
          return shows.filter((show) => show.month === month);
        }
      })
    );
  }


  selectMonth(month) {
    this.monthsForm.get('month').setValue(month);
  }

  setDisplay(type) {
    localStorage.setItem('sb-show-card-display', type);
    this.display = type;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

