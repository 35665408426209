import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FetchPastShows, SelectYear } from '../../state/shows/shows';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'app-past-shows',
  templateUrl: './past-shows.component.html',
  styleUrls: ['./past-shows.component.scss']
})
export class PastShowsComponent implements OnInit, OnDestroy {
  shows$: Observable<any[]>;
  years$: Observable<any>;
  selectedYear$: Observable<string>;
  showsByYear$: Observable<[]>;
  display = 'grid';
  subscriptions: Subscription[] = [];
  yearsForm: UntypedFormGroup;
  constructor(
    private store$: Store<any>,
  ) { }

  ngOnInit() {
    this.store$.dispatch(new FetchPastShows());
    this.shows$ = this.store$.select('showsByYear');
    this.selectedYear$ = this.store$.select('selectedYear');
    this.years$ = this.store$.select('years');
    this.yearsForm = new UntypedFormGroup({
      year: new UntypedFormControl('2010')
    });

    this.subscriptions.push(
      combineLatest([
        this.years$,
        this.selectedYear$
      ]).subscribe(([years, selectedYear]) => {
        if (selectedYear) {
          this.yearsForm.get('year').setValue(selectedYear);
        } else if (years && years[0]) {
          this.store$.dispatch(new SelectYear(years[0]));
          this.yearsForm.get('year').setValue(years[0]);
        }
      })
    );

    this.subscriptions.push(
      this.yearsForm.get('year').valueChanges.subscribe((value) => {
        this.store$.dispatch(new SelectYear(value));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
