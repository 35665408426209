<div class="row" *ngIf="(photographers$ | async) as photographers">
    <div class="offset-1 col-10">
      <h1>Photographers</h1>
      <p class="mb-4">We’re lucky to have a group of talented photographers capturing images from each of our sessions. Thanks to their skillful eyes, we keep a visual record of every artist who performs here.</p>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-4 mb-4" *ngFor="let member of photographers">
          <div class="staff-image" [style.background]="'url(' + member.image + ')'"></div>
          <h3>{{ member.name }}</h3>
          <div [innerHTML]="member.bio"></div>
        </div>
      </div>
    </div>
  </div>