import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FetchDesigners, FetchPosters, IDesigner, SelectDesigner } from '../../state/staff/designers';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-designers',
  templateUrl: './designers.component.html',
  styleUrls: ['./designers.component.scss']
})
export class DesignersComponent implements OnInit {
  designers$: Observable<IDesigner[]>;
  posters$: Observable<any>;
  constructor(
    private store$: Store<any>,
    private router: Router
  ) { }

  ngOnInit() {
    this.store$.dispatch(new FetchPosters());
    this.store$.dispatch(new FetchDesigners());
    this.designers$ = this.store$.select('designers');
    this.posters$ = this.store$.select('posters');
  }

  getDesigner(designer) {
    this.store$.dispatch(new SelectDesigner(designer));
    this.router.navigate([`designers/${designer.id}`]);
  }

}
