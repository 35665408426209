import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShowsService {

  constructor(private http: HttpClient) { }


  getRoofDonors() {
    return this.http.get(`assets/data/roof.json`, { observe: 'response' });
  }

  getFaq() {
    return this.http.get(`assets/pages/faq.json`, { observe: 'response' });
  }

  getStaff() {
    return this.http.get(`${environment.apiHost}/api/getTeam.php`, { observe: 'response' });
  }

  getDesigners() {
    return this.http.get(`${environment.apiHost}/api/getDesigners.php`, { observe: 'response' });
  }

  getPosters() {
    return this.http.get(`${environment.apiHost}/api/getPosters.php`, { observe: 'response' });
  }

  getUpcomingShows() {
    return this.http.get(`${environment.apiHost}/api/upcomingShows.php`, { observe: 'response' });
  }

  getPastShows() {
    return this.http.get(`${environment.apiHost}/api/pastShows.php`, { observe: 'response' });
  }

  getShow(id) {
    return this.http.get(`${environment.apiHost}/api/getShow.php?show=${id}`, { observe: 'response' });
  }

  getShowPics(id) {
    return this.http.get(`${environment.apiHost}/api/getShowPics.php?show=${id}`, { observe: 'response' });
  }
}
