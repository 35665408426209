import { IPayloadAction } from '../payloadAction';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { ShowsService } from '../../services/shows.service';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { switchMap, withLatestFrom, catchError, mergeMap, map, tap, combineLatest, filter } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as uniq from 'lodash/uniq';
import { getOldOrNewFile, getPosterOrFeaturedImgUrl, getPosterOrFeaturedImgThumbUrl } from '../../util/fileUrl';
import { NoOp } from '../staff/ourTeam';

export const FETCH_UPCOMING_SHOWS = '[Shows] fetch';
export const FETCH_UPCOMING_SHOWS_SUCCESS = '[Shows] fetch success';
export const FETCH_PAST_SHOWS = '[Shows] fetch past';
export const FETCH_PAST_SHOWS_SUCCESS = '[Shows] fetch past success';
export const SELECT_MONTH = '[Month] select';
export const SET_MONTHS = '[Months] set';
export const SET_FEATURED_SHOWS = '[FEATURED] set';
export const SELECT_YEAR = '[Years] select';
export const SET_YEARS = '[Years] set';
export const SET_SHOWS_BY_YEAR = '[Years] set shows by year';

export class FetchUpcomingShows implements IPayloadAction {
  readonly type = FETCH_UPCOMING_SHOWS;
}

export class FetchUpcomingShowsSuccess implements IPayloadAction {
  readonly type = FETCH_UPCOMING_SHOWS_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchPastShows implements IPayloadAction {
  readonly type = FETCH_PAST_SHOWS;
}

export class FetchPastShowsSuccess implements IPayloadAction {
  readonly type = FETCH_PAST_SHOWS_SUCCESS;
  constructor(public payload: any) { }
}

export class SetMonths implements IPayloadAction {
  readonly type = SET_MONTHS;
  constructor(public payload: any) { }
}

export class SetFeaturedShows implements IPayloadAction {
  readonly type = SET_FEATURED_SHOWS;
  constructor(public payload: any) { }
}

export class SelectMonth implements IPayloadAction {
  readonly type = SELECT_MONTH;
  constructor(public payload: string) { }
}

export class SetYears implements IPayloadAction {
  readonly type = SET_YEARS;
  constructor(public payload: any) { }
}

export class SelectYear implements IPayloadAction {
  readonly type = SELECT_YEAR;
  constructor(public payload: string) { }
}

export class SetShowsByYear implements IPayloadAction {
  readonly type = SET_SHOWS_BY_YEAR;
  constructor(public payload: any) { }
}


export const initialUpcomingShowsState: any = [];
export const initialPastShowsState: any = [];
export const initialMonthsState: any = [];
export const initialSelectedMonthState = 'all';
export const initialFeaturedShowsState: any = [];
export const initialYearsState: any = [];
export const initialSelectedYearState = '';
export const initialShowsByYear: any = [];

export function upcomingShowsReducer(shows: any, action: IPayloadAction) {
  switch (action.type) {
    case FETCH_UPCOMING_SHOWS_SUCCESS:
      return action.payload;
    default: {
      return shows;
    }
  }
}

export function pastShowsReducer(shows: any, action: IPayloadAction) {
  switch (action.type) {
    case FETCH_PAST_SHOWS_SUCCESS:
      return action.payload;
    default: {
      return shows;
    }
  }
}

export function monthsReducer(months: any, action: IPayloadAction) {
  switch (action.type) {
    case SET_MONTHS:
      return action.payload;
    default: {
      return months;
    }
  }
}

export function selectedMonthReducer(month: string, action: IPayloadAction) {
  switch (action.type) {
    case SELECT_MONTH:
      return action.payload;
    default: {
      return month;
    }
  }
}

export function yearsReducer(years: any, action: IPayloadAction) {
  switch (action.type) {
    case SET_YEARS:
      return action.payload;
    default: {
      return years;
    }
  }
}

export function selectedYearReducer(year: string, action: IPayloadAction) {
  switch (action.type) {
    case SELECT_YEAR:
      return action.payload;
    default: {
      return year;
    }
  }
}

export function featuredShowsReducer(shows: any, action: IPayloadAction) {
  switch (action.type) {
    case SET_FEATURED_SHOWS:
      return action.payload;
    default: {
      return shows;
    }
  }
}

export function showsByYearReducer(shows: any, action: IPayloadAction) {
  switch (action.type) {
    case SET_SHOWS_BY_YEAR:
      return action.payload;
    default: {
      return shows;
    }
  }
}

@Injectable()
export class ShowsEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private showsService: ShowsService,
  ) {
  }

  fetchUpcomingShows$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_UPCOMING_SHOWS),
    withLatestFrom(this.store$.select('upcomingShows')),
    mergeMap(([action, upcomingShows]: [IPayloadAction, any[]]) => {
      if (upcomingShows && upcomingShows.length > 0) {
        return of(new NoOp());
      }

      const getShowsRequest = this.showsService.getUpcomingShows();

      return from(getShowsRequest).pipe(
        mergeMap((result: any) => {
          const shows = result.body.map((show) => {
            return {
              ...show,
              featuredImageOrPoster: getPosterOrFeaturedImgUrl(show),
              featuredImageOrPosterThumb: getPosterOrFeaturedImgThumbUrl(show),
              // featuredImageUrl: getOldOrNewFile(show.FeaturedImageName, show.FeaturedImageHash, show.FeaturedImageOldName)
            };
          });
          return of(
            new FetchUpcomingShowsSuccess(this.getShowsByMonth(shows)),
            new SetFeaturedShows(this.getFeaturedShows(shows)),
            new SetMonths(this.getMonths(shows)),
          );
        }),
        catchError((error) => of(new FetchUpcomingShowsSuccess([])))
      );
    }),
  ));

  fetchPastShows$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_UPCOMING_SHOWS),
    withLatestFrom(this.store$.select('pastShows')),
    mergeMap(([action, pastShows]: [IPayloadAction, any[]]) => {
      if (pastShows && pastShows.length > 0) {
        return of(new NoOp());
      }

      const getShowsRequest = this.showsService.getPastShows();

      return from(getShowsRequest).pipe(
        mergeMap((result: any) => {
          const shows = result.body.map((show) => {
            return {
              ...show,
              featuredImageOrPoster: getPosterOrFeaturedImgUrl(show),
              featuredImageOrPosterThumb: getPosterOrFeaturedImgThumbUrl(show),
            };
          });
          return of(
            new FetchPastShowsSuccess(shows),
            new SetYears(this.getYears(shows))
          );
        }),
        catchError((error) => of(new FetchPastShowsSuccess([])))
      );
    }),
  ));

  setShowsByYear$ = createEffect(() => this.actions$.pipe(
    ofType(SELECT_YEAR),
    withLatestFrom(this.store$.select('pastShows')),
    mergeMap(([action, pastShows]: [IPayloadAction, any[]]) => {
      const filteredShows = pastShows.filter((show) => show.Date.substring(0, 4) === action.payload);
      return of(new SetShowsByYear(filteredShows));
    }),
  ));


  getShowsByMonth = (shows) => {
    const splitShowsObj = {};
    shows.forEach((show) => {
      const month = dayjs(show.Date).format('MMMM');
      if (!splitShowsObj[month]) {
        splitShowsObj[month] = { shows: [] };
      }
      splitShowsObj[month].shows.push(show);

    });
    const monthsArray = Object.keys(splitShowsObj);
    const monthsWithShows = monthsArray.map((month) => {
      return { month, shows: splitShowsObj[month].shows };
    });
    return monthsWithShows;
  }

  getMonths = (shows) => {
    const months = [];
    shows.forEach((show) => {
      const month = dayjs(show.Date).format('MMMM');
      months.push(month);
    });
    return uniq(months);
  }

  getYears = (shows) => {
    const years = [];
    shows.forEach((show) => {
      const year = dayjs(show.Date).format('YYYY');
      years.push(year);
    });
    return uniq(years);
  }

  getFeaturedShows = (shows) => {
    return shows.filter((show) => show.Featured === '1');
  }
}
