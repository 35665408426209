import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TicketsService } from 'src/app/services/tickets.service';

@Component({
  selector: 'app-enable-ticket',
  templateUrl: './enable-ticket.component.html',
  styleUrls: ['./enable-ticket.component.scss']
})
export class EnableTicketComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  ticketUpdate;

  constructor(
    private route: ActivatedRoute,
    private ticketsService: TicketsService,
  ) { }

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        this.ticketsService.setTicketTransferAvailable(params['guid'])
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((message) => {
          this.ticketUpdate = message['message'];
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
