import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FetchTeam } from '../../state/staff/ourTeam';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {
  team$: Observable<any[]>;
  constructor(
    private store$: Store<any>,
  ) { }

  ngOnInit() {
    this.store$.dispatch(new FetchTeam());
    this.team$ = this.store$.select('team');
  }

}
