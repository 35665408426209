import { ShowsEffects } from './shows/shows';
import { ShowEffects } from './show/show';
import { teamEffects } from './staff/ourTeam';
import { designerEffects } from './staff/designers';

export const effects = [
  ShowsEffects,
  ShowEffects,
  teamEffects,
  designerEffects,
];
