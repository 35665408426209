<div class="row" [ngClass]="{'position-fixed': fixed}">
  <div class="col-sm-12 col-md-4 col-xl-12 sidebar-widget">
    <h2 class="h4">Subscribe to our mailing list</h2>
    <app-mailchimp></app-mailchimp>
  </div>
  <div class="col-sm-12 col-md-4 col-xl-12 sidebar-widget" *ngIf="showSpotify">
    <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/7G87mPileY6S89Jy09esg7?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  </div>
  <!-- <div class="col-sm-12 col-md-4 col-xl-12 sidebar-widget featured-show">
    <h2 class="h4">Featured</h2>
      // TODO: this below syntax will fail when grabbing the first item in the index
      <app-show-card [show]="(shows$ | async)[0]"></app-show-card>
  </div> -->
  <!-- <div class="col-sm-12 col-md-4 col-xl-12 sidebar-widget">
    <h2 class="h4">Sh*tty Merch</h2>
    <p>If you'd like to order some Sh*tty Barn apparel, visit our online store: <a target="_blank" href="https://shitty2020.itemorder.com/">https://shitty2020.itemorder.com/.</a></p>
    <p>Orders must be placed by 9/21/20.</p>
  </div> -->
  <!-- <app-roof-widget class="col-sm-12 col-md-4 col-xl-12"></app-roof-widget> -->
</div>
