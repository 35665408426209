<div *ngIf="!(show$ | async) as show" class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<ng-container *ngIf="(show$ | async) as show">
  <div class="row"  [class.rogue]="isRogue(show)">
    <div *ngIf="isRogue(show)" class="rogue-page-background">
    </div>
    <div class="col-lg-12 col-xl-11 offset-xl-1">
      <h1><span *ngIf="isRogue(show)">Rogue </span>Session {{show.SessionNumber}}: {{show.Title}}</h1>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-1  overflow-hidden">
      <h2>{{show.Date | date: 'mediumDate' }}<span *ngIf="!show.isPast"> | <span *ngIf="show.Soldout !== '1'">${{show.TicketPrice}}<span *ngIf="show.TicketUrl"> | <a href="{{show.TicketUrl}}" target="_blank" class="btn btn-primary buy-tickets">Buy Tickets</a></span></span><span *ngIf="show.Soldout === '1'">SOLD OUT</span></span></h2>
      <div class="showTime" *ngIf="!show.isPast">{{show.StartTime}}</div>

      <!-- <div class="speaker-chair-icons">
        <app-speaker *ngIf="['2', '3', '4'].indexOf(show.Loudness) !== -1"></app-speaker>
        <app-chair *ngIf="show.StandingOnly === '1'"></app-chair>
      </div> -->
      <img *ngIf="isRogue(show)" src="/assets/images/rogue.png" alt="rogue session" class="rogue-mobile" />
      <a class="rogueLinkVerbose" *ngIf="isRogue(show)" [routerLink]="['/rogue-sessions']" target="_blank">What is a "Rogue" show?</a>
      <br /><br />
      <div class="copy" [innerHTML]="show.Copy" [style.maxHeight]="copyHeight"></div>
      <div *ngIf="copyHeight !== '100%'" class="gradient"></div>
      <button class="mt-4 mb-4" (click)="showMore()">{{showButtonText}}</button>
      <h3>Links</h3>
      <p>
        <span *ngIf="show.Facebook1" class="mr-2"><a href="{{show.Facebook1}}" target="_blank"><span [innerHTML]="convertLinkToIcon(show.Facebook1)"></span></a></span>
        <span *ngIf="show.Facebook2" class="mr-2"><a href="{{show.Facebook2}}" target="_blank"><span [innerHTML]="convertLinkToIcon(show.Facebook2)"></span></a></span>
        <span *ngIf="show.Website1" class="mr-2"><a href="{{show.Website1}}" target="_blank"><span [innerHTML]="convertLinkToIcon(show.Website1)"></span></a></span>
        <span *ngIf="show.Website2" class="mr-2"><a href="{{show.Website2}}" target="_blank"><span [innerHTML]="convertLinkToIcon(show.Website2)"></span></a></span>
      </p>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-5">
      <div class="poster" *ngIf="show.PosterUrl">
        <img class="card-img-top" alt="{{show.Title}}" defaultImage="assets/images/default.png" [lazyLoad]="show.PosterUrl" >
        <div class="designedBy" *ngIf="show.PosterDesignedBy">{{show.PosterDesignedBy}}</div>
      </div>
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" *ngIf="show.FeaturedImageName">
          <div class="carousel-inner">
            <div class="carousel-item active" *ngIf="show.FeaturedImageUrl1">
              <img class="d-block w-100" alt="{{show.FeatureImageName}}" defaultImage="assets/images/default.png" [lazyLoad]="show.FeaturedImageUrl1" />
            </div>
            <div class="carousel-item" *ngIf="show.FeaturedImageUrl2">
              <img class="d-block w-100" alt="{{show.FeatureImageName2}}" defaultImage="assets/images/default.png" [lazyLoad]="show.FeaturedImageUrl2" />
            </div>
            <ng-container *ngIf="showPics && showPics.length">
              <div class="carousel-item" *ngFor="let pic of showPics">
                <img class="d-block w-100" alt="_blank" defaultImage="assets/images/default.png" [lazyLoad]="getShowImageUrl(pic.ImageName)" />
              </div>
            </ng-container>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xl-11 offset-xl-1">
      <h2>Media</h2>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-1 mb-4" *ngIf="show.FeaturedVideo">
      <iframe width="100%" height="300" [src]="extractYouTubeId(show.FeaturedVideo)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-5 mb-4" *ngIf="show.FeaturedVideo2">
      <iframe width="100%" height="300" [src]="extractYouTubeId(show.FeaturedVideo2)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-1 mb-4" *ngIf="show.FeaturedAudio">
      <iframe [src]="extractOrReturnUrl(show.FeaturedAudio)" width=100% height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-5 mb-4" *ngIf="show.FeaturedAudio2">
      <iframe [src]="extractOrReturnUrl(show.FeaturedAudio2)" width=100% height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div>
  </div>
</ng-container>